import { Datagrid, DateField, FunctionField, List, ListContextProvider, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { TicketShow } from "./ticketShow";

const ticketFilters = [
    <TextInput source='Ticket.$contains' resettable />,
    <TextInput source='Source.$contains' resettable />,
    <TextInput source='Campaign.$contains' resettable />,
    <TextInput source='CustomerId.$contains' resettable />
];

export const TicketList = () =>{
    return (
        <List filters={ticketFilters} exporter={false}>
            <Datagrid expand={<TicketShow/>} rowClick='expand' bulkActionButtons={false} expandSingle >
                <FunctionField label='Code' render={(record: any) => record?.Code?.substring(0, 10).concat('...') ?? ''} />
                <FunctionField label='Ticket' render={(record: any) => record?.Ticket?.substring(0, 10).concat('...') ?? ''} />
                <TextField source="CustomerId" label="Customer Id" />
                <UrlField source='Source' />
                <TextField source='Campaign' />
                <DateField source='ValidFrom' />
                {/* <FunctionField label='Count' render={(record: any) => record.Requests.length} /> */}
            </Datagrid>
        </List>
    );
}