import { ReactNode, createElement } from "react";
import { MenuItemLink, ResourceDefinition, ResourceDefinitions, useTranslate } from "react-admin";
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { ReactComponentLike } from "prop-types";

export type MenuEntries = 'MTS' | 'SyncAll' | 'Sync' | 'DataSync' | 'SMPP';

export type GroupedChildren = {
    [groupName in MenuEntries | 'noGroup']: ReactNode[];
};

export default class MenuService {

    private readonly resourcesArray;
    private readonly dense;
    private readonly t = useTranslate();

    public constructor(resources: ResourceDefinitions, dense: boolean = false) {
        this.resourcesArray = [...Object.keys(resources)
            .map(name => (resources[name]))];
        this.dense = dense;
    }

    private readonly resourcesGroups: { name: string, group: MenuEntries }[] = [
        { name: 'mts_tickets', group: 'MTS' },
        { name: 'mts_rules', group: 'MTS' },
        { name: 'mts_elk_alerts', group: 'MTS' },
        { name: 'allBrokenCount/autoStatList', group: 'MTS'},

        { name: 'qk_run_export', group: 'SyncAll' },
        { name: 'qk_download_files', group: 'SyncAll' },
        { name: 'qk_send_to_ucrm', group: 'SyncAll' },
        { name: 'qk_send_to_mindbox', group: 'SyncAll' },

        /*{ name: 'qk_send_to_mindbox', group: 'Sync' },
        { name: 'audit_api', group: 'Sync' },*/

        { name: 'qk_datasync_sync_consumer', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumercommunicationsubscription', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerproductinspectionrequests', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumertasksteps', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerfirstacquisition', group: 'DataSync' },
        { name: 'qk_datasync_sync_person', group: 'DataSync' },
        { name: 'qk_datasync_sync_surveys', group: 'DataSync' },
        { name: 'qk_datasync_sync_product', group: 'DataSync' },
        { name: 'qk_datasync_sync_lost_product', group: 'DataSync' },
        { name: 'qk_datasync_sync_equipmentproductinstance', group: 'DataSync' },
        { name: 'qk_datasync_sync_order', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerallocations', group: 'DataSync' },
        { name: 'qk_datasync_sync_lendingorder', group: 'DataSync' },
        { name: 'qk_datasync_sync_availabilitychannel', group: 'DataSync' },
        { name: 'qk_datasync_sync_lastretailbrand', group: 'DataSync' },
        { name: 'qk_datasync_sync_lastretailbrandreference', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerfirstaccessorypurchase', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerfirstaccessorypurchasecancel', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerfirstconsumablepurchase', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerfirstconsumablepurchasecancel', group: 'DataSync' },
        { name: 'qk_datasync_sync_lastactivedevice', group: 'DataSync' },
        { name: 'qk_datasync_sync_lastactivedevicereference', group: 'DataSync' },
        { name: 'qk_datasync_sync_consumerallocations', group: 'DataSync' },
        { name: 'qk_datasync_sync_retailcalltask', group: 'DataSync' },
        { name: 'qk_datasync_sync_questionnaire', group: 'DataSync'},
        { name: 'qk_datasync_sync_vulnerabledevice', group: 'DataSync'},
        { name: 'qk_datasync_sync_cleaningrequests', group: 'DataSync' },
        { name: 'qk_datasync_sync_geographyconsumer', group: 'DataSync' },
        { name: 'qk_datasync_sync_productinspection', group: 'DataSync'}, 
        { name: 'qk_datasync_sync_freesms', group: 'DataSync'},
        { name: 'allBrokenCount/allBrokenCount', group: 'DataSync'},
        { name: 'smppconnections', group: 'SMPP' },
        { name: 'smppsettings', group: 'SMPP' },
        { name: 'smppperformance', group: 'SMPP' },
        { name: 'allBrokenCount/smppStatList', group: 'SMPP'},
        { name: 'dataintegritylogs', group: 'DataSync' },
        { name: 'rapporto_lost', group: 'DataSync' },
        { name: 's3services', group: 'DataSync' },
        { name: 's3validation_log', group: 'DataSync' },
        { name: 'queuelags', group: 'DataSync' },
        { name: 'mindboxlogs', group: 'DataSync' },
    ]

    private readonly groupsIcons: { name: MenuEntries, icon: ReactComponentLike }[] = [
        { name: 'MTS', icon: SellOutlinedIcon },
        { name: 'SyncAll', icon: ListAltOutlinedIcon },
        { name: 'Sync', icon: ContentPasteSearchOutlinedIcon },
        { name: 'DataSync', icon: SettingsOutlinedIcon },
        { name: 'SMPP', icon: SmsIcon }
    ]

    public getIconByGroupname(name: MenuEntries): ReactComponentLike {
        const icon = this.groupsIcons.find(gi => (gi.name === name))?.icon;
        return icon || FormatListBulletedIcon;
    }

    private getGroupByResName = (resName: string): MenuEntries | undefined => {
        let group: MenuEntries | undefined;
        this.resourcesGroups.forEach(resGroup => {
            if (resGroup.name === resName) (group = resGroup.group as MenuEntries)
        })

        return group;
    }

    public getGroupByResourceName(resName: string) {
        return this.getGroupByResName(resName);
    }

    private getTitleFromResName(name: string, options?: any) {
        const translateTitle = this.t(`cp.menuGroups.labels.${name}`)

        return translateTitle
    }

    public getTitleFromResourceName(name: string) {
        return this.getTitleFromResName(name);
    }

    public getSideMenuItems(): GroupedChildren {
        let groupedChildren: GroupedChildren = {
            MTS: [],
            SyncAll: [],
            Sync: [],
            DataSync: [],
            SMPP: [],
            noGroup: []
        };
        this.resourcesArray.forEach((res: ResourceDefinition) => {

            const { name, icon, options } = res;

            const group = this.getGroupByResName(name);
            const title = this.getTitleFromResName(name, options)

            const groupName: MenuEntries | 'noGroup' = group ? group : 'noGroup'; //for elements without group
            const elementIcon = !group && icon ? createElement(icon) : undefined

            const item = <MenuItemLink
                sx={{ fontWeight: 400, fontSize: '0.9em' }}
                key={name}
                to={'/' + name}
                state={{ _scrollToTop: true }}
                primaryText={title}
                leftIcon={elementIcon}
                dense={this.dense}
            />

            groupedChildren[groupName].push(item)
        });

        return groupedChildren;
    }
}