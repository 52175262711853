import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { MenuProps, useResourceDefinitions, useSidebarState, useTranslate } from "react-admin";
import SubMenu from "./subMenu";
import MenuEntriesService, { MenuEntries } from './menuEntriesService';
import { useGetPagelocation } from "./useGetPageLocation";

export const Menu = ({dense = false} : MenuProps) => {    
    const resources = useResourceDefinitions();
    const [primaryGroup, setPrimaryGroup] = useState<string>();
    const [state, setState] = useState({
        MTS: true,
        SyncAll: true,
        Sync: true,
        DataSync: true,
        Smpp: true
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const { menuGroup } = useGetPagelocation();
    
    useEffect(() => {
        if (menuGroup !== primaryGroup) {
            setPrimaryGroup(menuGroup as MenuEntries);
            setState({ ...state, [menuGroup as MenuEntries]: true })
        }
    }, [menuGroup])


    useEffect(() => {
        if (open) {
            isAllClosed() && !!primaryGroup && setState({ ...state, [primaryGroup as string]: true })
        } else {
            closeAll();
        }
    }, [open])

    const isAllClosed = () => {
        let isClosed = true;
        Object.keys(state).forEach(stt => {
            if (state[stt as keyof typeof state]) {
                isClosed = false
            };
        })
        return isClosed;
    }

    const closeAll = () => {
        let newState = Object.assign({}, state);
        Object.keys(state).forEach(stat => newState[stat as keyof typeof newState] = false);
        setState(newState);
    }

    
    const menuEntriesService = new MenuEntriesService(resources, dense);const 
    groupedChildren = menuEntriesService.getSideMenuItems();


    const handleToggle = (menu: MenuEntries) => {
        setState(state => ({ ...state, [menu]: !state[menu as keyof typeof state] }));
    };

    const childTree = Object.keys(groupedChildren).map((groupName) => {
        if (groupName !== 'noGroup') {
            const menuGroupName = groupName as MenuEntries;
            const children = groupedChildren[menuGroupName];
            if (children.length > 0) {
                return (
                    <SubMenu
                        key={menuGroupName}
                        handleToggle={() => handleToggle(menuGroupName)}
                        isOpen={state[menuGroupName as keyof typeof state]}
                        isMenuOpen={open}
                        isPrimary={primaryGroup === menuGroupName}
                        name={menuGroupName}
                        icon={menuEntriesService.getIconByGroupname(menuGroupName)}
                        dense={dense}
                    >
                        {children}
                    </SubMenu>
                )
            } else {
                return null
            }

        } else {
            return [...groupedChildren[groupName]]
        }
    })

    return (
        <Box
            sx={{
                width: open ? 300 : 45,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme: any) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {childTree}
        </Box >
    );
}