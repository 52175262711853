import { DateTimeInput, Edit, SimpleForm, TextInput } from "react-admin";
import { Grid } from "@mui/material";


export const S3validationlogShow = () => {
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source="Created" disabled fullWidth/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="Topic" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <TextInput source="ValidateResult" multiline disabled fullWidth />
                    </Grid>
                </Grid>                
            </SimpleForm>
        </Edit>
    );
}