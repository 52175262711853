import { Alert, Card, CardContent, CardHeader } from '@mui/material';
import { Datagrid, DateField, DateInput, List, NumberField, useListContext, TextField, ArrayInput, SelectInput, useGetList } from 'react-admin';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { addDays } from 'date-fns';
import _ from 'lodash';

const postFilters = [
    <DateInput source="LogDateTime.$ge" label="LogDateTime From" defaultValue={addDays(new Date(), -1)} />,
    <DateInput source="LogDateTime.$le" label="LogDateTime To" defaultValue={new Date()} />,
    <SelectInput source="SystemName" label="SystemName" choices={[
        { id: 'AddFraudStatusCC', name: 'AddFraudStatusCC' },
        { id: 'SelfRegCouponCommunications', name: 'SelfRegCouponCommunications' },
        { id: 'GetAuthTicketBackendIqosRu', name: 'GetAuthTicketBackendIqosRu' },
        { id: 'ImportConsumerChannelAvailability', name: 'ImportConsumerChannelAvailability' },
        { id: 'PartnerMoneyRewardCommunicationTradeIn', name: 'PartnerMoneyRewardCommunicationTradeIn' },
        { id: 'PartnerMoneyRewardCommunicationReward', name: 'PartnerMoneyRewardCommunicationReward' },
        { id: 'ImportUpgrade', name: 'ImportUpgrade' },
        { id: 'Importfirstacquisition', name: 'Importfirstacquisition' },
        { id: 'Importfirstconsumablepurchase', name: 'Importfirstconsumablepurchase' },
        { id: 'Importconsumergeography', name: 'Importconsumergeography' },
        { id: 'Importlastbrvisit', name: 'Importlastbrvisit' },
        { id: 'Importlastactivedevice', name: 'Importlastactivedevice' },
        { id: 'Importconsumerfirstaccessory', name: 'Importconsumerfirstaccessory' },
        { id: 'Importconsumerallocations', name: 'Importconsumerallocations' },
        { id: 'Importconsumer', name: 'Importconsumer' },
        { id: 'ImportSubscriptions', name: 'ImportSubscriptions' },
        { id: 'ImportPerson', name: 'ImportPerson' },
        { id: 'ImportOperatorRapporto', name: 'ImportOperatorRapporto' },
        { id: 'SurveyRequestNewcomersNPS', name: 'SurveyRequestNewcomersNPS' },
        { id: 'SurveyRequestNewcomersAdoption', name: 'SurveyRequestNewcomersAdoption' },
        { id: 'SurveyRequestActualizationNPS', name: 'SurveyRequestActualizationNPS' },
        { id: 'SurveyRequestActualizationAdoption', name: 'SurveyRequestActualizationAdoption' },
        { id: 'SurveyRequestUpgradeAdoption', name: 'SurveyRequestUpgradeAdoption' },
        { id: 'SurveyRequestUpgradeNPS', name: 'SurveyRequestUpgradeNPS' },
        { id: 'CheckConsumerCleaningRequest', name: 'CheckConsumerCleaningRequest' },
        { id: 'UpdateConsumerCleaningRequest', name: 'UpdateConsumerCleaningRequest' },
        { id: 'AddConsumerCleaningRequest', name: 'AddConsumerCleaningRequest' },
        { id: 'UpdateConsumerProductInspectionRequest', name: 'UpdateConsumerProductInspectionRequest' },
        { id: 'CheckProductInspectionRequest', name: 'CheckProductInspectionRequest' },
        { id: 'Website.IQOS.SetUpCart', name: 'Website.IQOS.SetUpCart' },
        { id: 'Website.IQOS.ClientEnteredCartPage', name: 'Website.IQOS.ClientEnteredCartPage' },
        { id: 'Website.IQOS.ClientEnteredThankYouPage', name: 'Website.IQOS.ClientEnteredThankYouPage' },
        { id: 'Website.IQOS.ClientEnteredCheckoutPage', name: 'Website.IQOS.ClientEnteredCheckoutPage' },
        { id: 'Website.IQOS.AuthorizedCustomerOpenSite', name: 'Website.IQOS.AuthorizedCustomerOpenSite' },
        { id: 'SendActDevicesReturn', name: 'SendActDevicesReturn' },
        { id: 'CheckIfDeperson', name: 'CheckIfDeperson' },
        { id: 'SendActDevicesHandOut', name: 'SendActDevicesHandOut' },
        { id: 'TGBotTicket', name: 'TGBotTicket' },
        { id: 'AddProductInspectionRequest', name: 'AddProductInspectionRequest' },
        { id: 'GetOrderListNotSaleAndCompleted', name: 'GetOrderListNotSaleAndCompleted' },
        { id: 'Upgrade', name: 'Upgrade' },
        { id: 'ViberAction', name: 'ViberAction' },
        { id: 'GetOrderToAsset', name: 'GetOrderToAsset' },
        { id: 'ImportStatusovIzTelegram', name: 'ImportStatusovIzTelegram' },
        { id: 'EditConsumerAllocations', name: 'EditConsumerAllocations' },
        { id: 'SendPromotionCodesToConsumer', name: 'SendPromotionCodesToConsumer' },
        { id: 'FreeFormCommunication', name: 'FreeFormCommunication' },
        { id: 'EditCustomer', name: 'EditCustomer' },
        { id: 'AssetToOrdersSync', name: 'AssetToOrdersSync' },
        { id: 'AddStatusOrderToAsset', name: 'AddStatusOrderToAsset' },
        { id: 'ProverkaSushhestvovaniyaZakaza', name: 'ProverkaSushhestvovaniyaZakaza' },
        { id: 'AssetToOrders', name: 'AssetToOrders' },
        { id: 'OpportunityToOdersSync', name: 'OpportunityToOdersSync' },
        { id: 'OpportunityToOders', name: 'OpportunityToOders' },
        { id: 'GetClientProfile', name: 'GetClientProfile' },
        { id: 'AddOpportunityOrder', name: 'AddOpportunityOrder' },
        { id: 'CheckExists', name: 'CheckExists' },
        { id: 'EditSubscribesCustomer', name: 'EditSubscribesCustomer' },
        { id: 'AssetToProduct', name: 'AssetToProduct' }
    ]}/>,
    <SelectInput source="Status" label="Status" choices={[
        { id: 'Completed', name: 'Completed' },
        { id: 'Failed', name: 'Failed' }
    ]}/>
];

const dateFormatter = (date: Date): string =>
    new Date(date).toLocaleString();

export const MindboxlogsList = () => {
    return (
        <List pagination={false} perPage={10000} filters={postFilters} sort={{ field: "LogDateTime", order: "ASC" }}>
            <>                
                <MindboxlogsChart></MindboxlogsChart>

                <Datagrid  bulkActionButtons={false} optimized sx={{ '& .column-Count': { textAlign: 'left' }}}>
                    <TextField source='SystemName' />
                    <DateField source='LogDateTime' showTime />
                    <TextField source='Status' label='Status' />
                    <NumberField source='Count' label='Msg. Count' />
                </Datagrid>
            </>
        </List>
    );
}

export const MindboxlogsChart = () => {
    var { data, isLoading, filterValues } = useListContext();
    if (isLoading) return null;
    if (filterValues.SystemName === undefined){
        var d = _.groupBy(data, car => car.LogDateTime)
        data = _.map(d, (item:Array<any>,k) => { return {
            LogDateTime: k,
            Count: item.reduce((partialSum, a) => partialSum + a.Count, 0),
            SystemName: 'All System Names'
        }  });
    }
    return (
        <Card>
            <CardHeader title={'Mindbox Logs'} />
            <CardContent>
                <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <AreaChart>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="LogDateTime"
                                name="Date"
                                tickFormatter={dateFormatter}
                            />
                            <YAxis dataKey="Count" name="Count"  label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
                            <Tooltip />
                            <Area type="monotone" data={data} dataKey="Count" stroke="#005396" fill="#005396" />;
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};