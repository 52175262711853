import { Datagrid, DateField, List, TextField } from "react-admin";

export const SmppConnections = () =>{
    return (
        <List exporter={false}>
            <Datagrid rowClick={undefined}>
                <DateField source='UpdateDate' showTime={true} />
                <TextField source="Name" />
                <TextField source="Status" />
            </Datagrid>
        </List>
    );
}