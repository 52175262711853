import { Datagrid, DateField, List, TextField, TextInput } from "react-admin";

const postFilters = [
    <TextInput source="Code.$contains" label="Queue" />,
];

export const QueueLagList = () =>{
    return (
        <List exporter={false} filters={postFilters}>
            <Datagrid rowClick={undefined}>
                <TextField source="Code" />
                <TextField source="Lag" />
                <DateField source='UpdateDate' showTime={true} />
            </Datagrid>
        </List>
    );
}