import { Datagrid, DateField, List, TextField } from "react-admin";


export const S3validationlogList = () =>{
    return (
        <List exporter={false}>
            <Datagrid rowClick="show" sort={{ field: "Created", order: "DESC" }}>
                <DateField source='Created' showTime={true} />
                <TextField source="Topic" />
            </Datagrid>
        </List>
    );
}